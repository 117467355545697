import './pricing.styles.scss';
import PricingCard from '../../components/pricing-card/pricing-card.component';
import {useTranslation} from '../../contexts/translation.context';


const Pricing = () => {

    const { t } = useTranslation();

    return(
        <div className='pricing-page'>
            <div className='pricing-headline'>
                <h1 className='pricing-headline-title'>{t("pricing.headline.title")}</h1>
                <p className='pricing-headline-paragraph'>{t("pricing.headline.paragraph")}</p>
                <div className='pricing-headline-line'></div>
            </div>
            <div className='pricing-cards'>
                <PricingCard />
            </div>
            <div className='pricing-footing'>
                <div className='pricing-footing-top'>
                    <h1  style={{margin: "0"}}> If you need additional information </h1>
                    <p style={{fontSize: "24px", margin: "0"}}> Please contact us </p>
                </div>
                <div  className='pricing-footing-bot'>
                    <p  style={{width: "30%",  textAlign:"right"}} >0661072512</p>
                    <div className='pricing-footing-bot-seperation'></div>
                    <p  style={{width: "30%"}}  >contact@ravostrat.com</p>
                </div>

            </div>
        </div>
    )
}

export default Pricing;