import './pricing-card.styles.scss';
import Button from '../button/button.component';


const PricingCard = () => {
    return(
        <>
            <div className='pricing-card-container' style={{width: "300px", height:"85%"}}>

                <div className='pricing-card-top'>Basic</div>
                <div className='pricing-card-bot'>
                    <div className='pricing-card-bot-price'>50$</div>
                    <div className='pricing-card-bot-list'>

                            <li>Qorem ipsum dolor sit amet</li>
                            <li>Qorem ipsum dolor sit amet</li>
                            <li>Qorem ipsum dolor sit amet</li>
                            <li>Qorem ipsum dolor sit amet</li>
                    </div>
                    <div className='pricing-card-bot-button'>
                        <Button buttonType="inverted">Subscribe</Button>
                    </div>
                </div>
            
            </div>
            <div className='pricing-card-container'  style={{width: "300px", height:"95%"}}>

                <div className='pricing-card-top'  style={{backgroundColor:"#613C3E"}}>Standard</div>
                <div className='pricing-card-bot'>
                    <div className='pricing-card-bot-price'>50$</div>
                    <div className='pricing-card-bot-list'>

                            <li>Qorem ipsum dolor sit amet</li>
                            <li>Qorem ipsum dolor sit amet</li>
                            <li>Qorem ipsum dolor sit amet</li>
                            <li>Qorem ipsum dolor sit amet</li>
                    </div>
                    <div className='pricing-card-bot-button'>
                        <Button buttonType="inverted">Subscribe</Button>
                    </div>
                </div>
            
            </div>
            <div className='pricing-card-container'  style={{width: "300px", height:"85%"}}>

                <div className='pricing-card-top'>Pro</div>
                <div className='pricing-card-bot'>
                    <div className='pricing-card-bot-price'>50$</div>
                    <div className='pricing-card-bot-list'>

                            <li>Qorem ipsum dolor sit amet</li>
                            <li>Qorem ipsum dolor sit amet</li>
                            <li>Qorem ipsum dolor sit amet</li>
                            <li>Qorem ipsum dolor sit amet</li>
                    </div>
                    <div className='pricing-card-bot-button'>
                        <Button buttonType="inverted">Subscribe</Button>
                    </div>
                </div>
            
            </div>

            
        </>
    )
}

export default PricingCard;