import Headline from "../../sections/headline/headline.section";
import Benefits from "../../sections/benefits/benefits.section";
import Goals from "../../sections/goals/goals.section";
import Aboutus from "../../sections/aboutus/aboutus.section";
import Faq from "../../sections/faq/faq.section";
import Contact from "../../sections/contact/contact.section";

import './home.scss'

const Home = () => {
    return(
        <div className="home-container">
            <Headline />
            <Benefits />
            <Goals />
            <Aboutus /> 
            <div  className="FAQ-line">
            </div>
            <div className="FAQ-headline"> Frequently Asked Questions</div>
            <Faq />
            <Contact />

        </div>
        
    )
}

export default Home;