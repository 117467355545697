import './aboutus.styles.scss';
import upperAboutusImage from './upper-aboutus-image.png'
import lowerAboutusImage from './lower-aboutus-image.png'




const Aboutus = () => {
    return (
        <div className='container'>
            <div className="upper-container">
                    <div className="upper-left-section">
                        <div className="upper-text-box">Lorem ipsum dolor sit amet consectetur</div>
                        <div className="upper-text-box1">Lorem ipsum dolor sit amet consectetur. Auctor semper habitasse mi tincidunt purus cras auctor amet amet. Morbi morbi mus consequat non feugiat nisi integer</div>
                    </div>

                
                    <div className="upper-right-section">
                        <div className="upper-grey-box"></div>
                        <img src={upperAboutusImage} alt="aboutus1" className="upper-top-image" />
                    </div>
            </div>
            <div className="lower-container">
                    <div className="lower-left-section">
                        <div className="lower-brown-box"></div>
                        <img src={lowerAboutusImage} alt="aboutus1" className="lower-top-image" />
                    </div>
                    <div className="lower-right-section">
                        <div className="lower-text-box">Lorem ipsum dolor sit amet consectetur</div>
                        <div className="lower-text-box1">Lorem ipsum dolor sit amet consectetur. Auctor semper habitasse mi tincidunt purus cras auctor amet amet. Morbi morbi mus consequat non feugiat nisi integer</div>
                    </div>

                
                    
            </div>
        </div>
    )
}


export default Aboutus;