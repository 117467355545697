
import './contact.styles.scss';



const Contact = () => {
    return(
        <div className='contact-section'>

            <div className='contact-section-container'>

                {/* <div className='contact-section-backtext' style={{textAlign: "right"}}> Connect </div> */}

                <div className='contact-form-container'>

                    <div className='contact-form-headline'> Connect with us</div>
                
                    <div className='contact-form-input-container'>

                        <div style={{position:"relative", width:"100%"}}>
                            <input className='contact-form-input' value={""} readOnly/> 
                            <label className='contact-form-label'>Name</label>
                        </div>

                        <div style={{position:"relative", width:"100%"}}>
                            <input className='contact-form-input' value={""} readOnly/> 
                            <label className='contact-form-label'>Email</label>
                        </div>


                        <div style={{position:"relative", width:"100%"}}>
                            <input className='contact-form-input' value={""} readOnly/> 
                            <label className='contact-form-label'>Number</label>
                        </div>

                        <div style={{position:"relative", width:"100%"}}>
                            <input className='contact-form-input' value={""} readOnly/> 
                            <label className='contact-form-label'>City</label>
                        </div>

                        <div style={{position:"relative", width:"100%", gridColumn:"span 2"}}>
                            <input className='contact-form-input' value={""} readOnly/> 
                            <label className='contact-form-label'>Details</label>
                        </div>

                    </div>

                </div>
                
                {/* <div className='contact-section-backtext' style={{marginLeft: "5%"}} > Ravostrat </div> */}
            
            </div>
            
        </div>
    )
}

export default Contact;