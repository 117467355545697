import './benefits.styles.scss';

import click from './click.png';
import loop from './loop.png';
import clock from './clock.png';


const Benefits = () => {
    return (
        <div className="benefits-section">
            <div className='benefit-container'>
                <img src={click} alt="clickpng" className='benefit-png'/>
                <div className='benefit-line'></div>
                <h1 className='benefit'>Effortless Shipment Posting</h1>
            </div>
            <div className='benefit-container'>
                <img src={loop} alt="looppng" className='benefit-png'/>
                <div className='benefit-line'></div>
                <h1 className='benefit'>Effortless Shipment Posting</h1>
            </div>
            <div className='benefit-container'>
                <img src={clock} alt="clockpng" className='benefit-png'/>
                <div className='benefit-line'></div>
                <h1 className='benefit'>Effortless Shipment Posting</h1>
            </div>
        </div>
    )
}

export default Benefits;