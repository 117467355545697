import { createContext, useContext, useState } from "react";
import en from "../assets/locales/en.json";


const translations = { en };

const TranslationContext = createContext();


export const TranslationProvider = ({ children }) => {
  const [language, setLanguage] = useState("en"); 


  const activeTranslations = translations[language];


  const t = (key) => {
    return key.split(".").reduce((obj, k) => obj?.[k], activeTranslations) || key;
  };

  return (
    <TranslationContext.Provider value={{ t, language, setLanguage }}>
      {children}
    </TranslationContext.Provider>
  );
};

export const useTranslation = () => useContext(TranslationContext);