import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
import { UserProvider } from './contexts/user.context';
import { TranslationProvider } from './contexts/translation.context';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
      <BrowserRouter>
        <UserProvider>
          <TranslationProvider>
            <App />
          </TranslationProvider>
        </UserProvider>
      </BrowserRouter>
  // </React.StrictMode>
);

reportWebVitals();
