import './faq.styles.scss';

const Faq = () => {
    return (
        <div className='faq-section'>
            <div className='faq-dropdown-container'>

                <div className='faq-dropdown'>  
                    <p className='faq-dropdown-question' >Lorem ipsum dolor sit amet consectetur. Auctor semper habitasse ?</p>
                    <div className='faq-dropdown-arrow'></div>
                </div>

                <div className='faq-dropdown'>  
                    <p className='faq-dropdown-question' >Lorem ipsum dolor sit amet consectetur. Auctor semper habitasse ?</p>
                    <div className='faq-dropdown-arrow'></div>
                </div>

                <div className='faq-dropdown'>  
                    <p className='faq-dropdown-question' >Lorem ipsum dolor sit amet consectetur. Auctor semper habitasse ?</p>
                    <div className='faq-dropdown-arrow'></div>
                </div>

                <div className='faq-dropdown'>  
                    <p className='faq-dropdown-question' >Lorem ipsum dolor sit amet consectetur. Auctor semper habitasse ?</p>
                    <div className='faq-dropdown-arrow'></div>
                </div>
                
            </div>
        </div>
    )
}


export default Faq;





