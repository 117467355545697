import './goals.styles.scss';
import  goalsImg1 from './goals-img1.png';
import  goalsImg2 from './goals-img2.png';

const Goals = () => {
    return (
        <div className='goals-section'>

            <h1 className='goals-header'>OUR GOAL</h1>

            <div className="goals-part1-container">
                <div className="content-part1-left" >  Beyond being a digital freight solution ... </div>
                <div className='content-part1-line'></div>
                <img src={goalsImg1} alt="goalsImg1" className="goals-part1-image-right" />
            </div>

            <div className="goals-part2-container">
                <div className='goals-part2-float'>
                    <div className="content-part2-right" >  we pride ourselves on delivering outstanding customer service  </div>
                    <div className='content-part2-line'></div>
                </div>
                <img src={goalsImg2} alt="goalsImg2" className="goals-part2-image-left" />
                
            </div>

        </div>
    )
}


export default Goals;