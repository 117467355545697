import {useState , useRef, useEffect} from 'react';
import Button from '../../components/button/button.component';
import box from '../../assets/img/trucks/box.jpg';
import flatbed from '../../assets/img/trucks/flatbed.jpg';
import refrigirated from '../../assets/img/trucks/refrigirated.jpg';
import semitrailer from '../../assets/img/trucks/semitrailer.jpg';
import deleteIcon from '../../assets/img/icons/delete.svg';
import './addtruck.style.scss'; 

const initialValues = {
    id: "",
    plaque: "",
    marque: "", 
    annee:"",
    type:"",
    typeImage:"",
    typeIsOpen: false,
    capacite:"",
    dimension:"",
    fuel:"",
    fuelIsOpen: false
  };

const Addtruck = () => {

    const [formFields, setFormFields] = useState(initialValues);

    const [trucksAdded, setTrucksAdded] = useState([

    ]);

    const [formType, setFormType] = useState("");

    const [addtruckWarningMessage, setAddtruckWarningMessage] = useState({type: "", message: "", action: null})

    const typeDropdownRef = useRef();

    const fuelDropdownRef = useRef();

    const handleChange = (event) => {

        setFormFields({...formFields, [event.target.name]: event.target.value})

    }

    const toggleDropdown = (event) => {

        setFormFields({...formFields, [event.target.dataset.toggle]: true})

    } 

    const handleDropdownChoiceClick = (event) => {

        setFormFields({...formFields, [event.target.closest("div").dataset.name]: event.target.dataset.name, [event.target.closest("div").dataset.toggle]: false, typeImage: event.target.dataset.image});

    }

    const handleSaveButton = () => {

        if (!formFields.plaque.length || !formFields.marque.length || !formFields.annee.length || !formFields.type.length || !formFields.capacite.length || !formFields.dimension.length || !formFields.fuel.length) {

            setAddtruckWarningMessage({type: "info", message: "You must enter all the necessary information in order to save"});

            return;

        }
       
        if (formType === "new") {

            const newTruck = {truckId: 'TR - '+ trucksAdded.length + 492 , plaque: formFields.plaque, marque: formFields.marque, annee: formFields.annee, type: formFields.type, capacite: formFields.capacite, dimension: formFields.dimension, fuel: formFields.fuel, typeImage: formFields.typeImage  };
            setTrucksAdded([...trucksAdded, newTruck]);

        }

        if (formType === "edit") {

            const editedTruck = {truckId: trucksAdded[formFields.id].truckId, plaque: formFields.plaque, marque: formFields.marque, annee: formFields.annee, type: formFields.type, capacite: formFields.capacite, dimension: formFields.dimension, fuel: formFields.fuel, typeImage: formFields.typeImage  };

            setTrucksAdded( prevTrucks => {

                const newTrucksAdded = [...prevTrucks]; 

                newTrucksAdded[formFields.id] = { ...editedTruck };

                console.log(newTrucksAdded)

                return newTrucksAdded;

            });

        }

        setFormType("");
        setFormFields(initialValues);
           
    }

    const handleDeleteButton = () => {

        setTrucksAdded( prevTrucks => {

            const newTrucksAdded = prevTrucks.filter((item, i) => i !== parseInt(formFields.id) );
            
            return newTrucksAdded;

        });

        setFormType("");
        setFormFields(initialValues);

    }

    const handleAddTruckButton = () => {
        setFormType("new")
    }

    const handleEditButton = (event) => {
        const truckIndex = event.target.dataset.id;
        setFormFields({...formFields, id:truckIndex, plaque: trucksAdded[truckIndex].plaque, marque: trucksAdded[truckIndex].marque, annee: trucksAdded[truckIndex].annee, type: trucksAdded[truckIndex].type, typeImage: trucksAdded[truckIndex].typeImage, capacite: trucksAdded[truckIndex].capacite, dimension: trucksAdded[truckIndex].dimension, fuel: trucksAdded[truckIndex].fuel })
        setFormType("edit");
    }

    const handleDismissButton = () => {
        setFormType("");
        setFormFields(initialValues);
    }

    useEffect(() => {
       
        const handleClickOutside = (event) => {

            if ( typeDropdownRef.current && !typeDropdownRef.current.contains(event.target)) {
                setFormFields((prev) => ({...prev, typeIsOpen: false, }));
              }

            if( fuelDropdownRef.current && !fuelDropdownRef.current.contains(event.target)){
                setFormFields( (prev) => ({...prev, fuelIsOpen: false}));
                
              }
          
         
        };
    
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
      }, [formFields]);


    return(
        <div className='addtruck-section'>  

           { 
           !trucksAdded.length ? 
            <div className={` ${formType === "" ? '' : 'addtruck-hide'} addtruck-button-empty-effect `} >
                <div className={` ${formType === "" ? '' : 'addtruck-hide'} addtruck-button-empty `} onClick={handleAddTruckButton}>
                        <div className='addtruck-round-center'>
                                <div className='addtruck-round-center-horizontal-line'></div>
                                <div className='addtruck-round-center-vertical-line'></div>
                        </div>
                        <div className='addtruck-text'>
                            Add Truck
                        </div>
                </div>
            </div>

            :
                
            <div className={` ${formType === "" ? '' : 'addtruck-hide'} addtruck-availabletrucks-container `}>

                {
                    trucksAdded.map((item, index) => (

                        <div key={index} className='addtruck-availabletrucks-card-effect'>
                            <div className='addtruck-availabletrucks-card' style={{backgroundImage: `url(${item.typeImage})`}}> 
                                <div className='addtruck-availabletrucks-card-edit' data-id={index} onClick={handleEditButton}> ✎ edit </div>
                                <div className='addtruck-availabletrucks-card-description'>
                                    <h4>{item.truckId}</h4>
                                    <p> {item.type} </p>
                                </div>
                            </div>
                        </div>

                    ))
                
                }


                 <div className='addtruck-availabletrucks-card-effect' style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px"}}>
                    <div className='addtruck-button' onClick={handleAddTruckButton}>
                        <div className='addtruck-round-center'>
                                <div className='addtruck-round-center-horizontal-line'></div>
                                <div className='addtruck-round-center-vertical-line'></div>
                        </div>
                        <div className='addtruck-text'>
                            Add Truck
                        </div>
                    </div>
                </div>
            </div>
            
            }


            <div className={` ${formType === "new" || formType === "edit" ? '' : 'addtruck-hide'} addtruck-new-form-container `}>

                <div className='addtruck-new-form-input-container'>
                    <div  style={{position:"relative", width:"100%"}}>
                        <input className='addtruck-form-input' name="plaque" value={formFields.plaque} onChange={handleChange}/> 
                        <label  className={` ${formFields.plaque.length ? 'addtruck-form-shrink' : ''} addtruck-form-label`}>Plaque d'immatriculation</label>
                    </div>

                    <div  style={{position:"relative", width:"100%"}}>
                        <input className='addtruck-form-input'  name="marque" value={formFields.marque} onChange={handleChange}/> 
                        <label className={` ${formFields.marque.length ? 'addtruck-form-shrink' : ''} addtruck-form-label`}>Marque et Model</label>
                    </div>

                    <div  style={{position:"relative", width:"100%"}}>
                        <input className='addtruck-form-input'  name="annee" value={formFields.annee} onChange={handleChange}/> 
                        <label className={` ${formFields.annee.length ? 'addtruck-form-shrink' : ''} addtruck-form-label`}>Annee</label>
                    </div>

                    <div ref={typeDropdownRef}  style={{position:"relative", width:"100%"}} >
                        <input   className='addtruck-form-input' autoComplete="off"  name="type" data-toggle='typeIsOpen' value={formFields.type} onChange={handleChange} onClick={toggleDropdown}/> 
                        <label  className={` ${formFields.type.length ? 'addtruck-form-shrink' : ''} addtruck-form-label`}>Type de Vehicule</label>
                        <div className={`addtruck-form-dropdown custom-scroll ${(formFields.typeIsOpen) ? '' : 'addtruck-hide' }`}  data-name="type"  data-toggle="typeIsOpen" data-image="typeImage">
                            <ul className='addtruck-form-dropdown-choices' data-name="Semi Trailer Truck" data-image={semitrailer} onClick={handleDropdownChoiceClick}>  Semi Trailer Truck</ul>
                            <ul className='addtruck-form-dropdown-choices' data-name="Refrigerated Truck" data-image={refrigirated} onClick={handleDropdownChoiceClick}>  Refrigerated Truck </ul>
                            <ul className='addtruck-form-dropdown-choices' data-name="Box Truck" data-image={box} onClick={handleDropdownChoiceClick}>  Box Truck </ul>
                            <ul className='addtruck-form-dropdown-choices' data-name="Flat Bed Truck" data-image={flatbed} onClick={handleDropdownChoiceClick}>  Flat Bed Truck</ul>
                        </div>
                    </div>

                    <div  style={{position:"relative", width:"100%"}}>
                        <input className='addtruck-form-input'  name="capacite" value={formFields.capacite} onChange={handleChange}/> 
                        <label className={` ${formFields.capacite.length ? 'addtruck-form-shrink' : ''} addtruck-form-label`}>Capacite en Poigt</label>
                    </div>

                    <div  style={{position:"relative", width:"100%"}}>
                        <input className='addtruck-form-input'  name="dimension" value={formFields.dimension} onChange={handleChange}/> 
                        <label className={` ${formFields.dimension.length ? 'addtruck-form-shrink' : ''} addtruck-form-label`}>Dimension</label>
                    </div>

                    <div ref={fuelDropdownRef} style={{position:"relative", width:"100%"}}>
                        <input className='addtruck-form-input' autoComplete="off"  name="fuel" data-toggle='fuelIsOpen' value={formFields.fuel} onChange={handleChange} onClick={toggleDropdown}/> 
                        <label className={` ${formFields.fuel.length ? 'addtruck-form-shrink' : ''} addtruck-form-label`}>Type de Fuel</label>
                        <div className={`addtruck-form-dropdown custom-scroll ${(formFields.fuelIsOpen) ? '' : 'addtruck-hide' }`}  data-name="fuel" data-toggle="fuelIsOpen" >
                            <p className='addtruck-form-dropdown-choices' data-name="Diesel" data-image={formFields.typeImage}  onClick={handleDropdownChoiceClick}>  Diesel </p>
                            <p className='addtruck-form-dropdown-choices' data-name="Gasoil" data-image={formFields.typeImage} onClick={handleDropdownChoiceClick}>  Gasoil </p>
                        </div>
                    </div>
                </div>
                <div className='addtruck-save-button-container'> 

                    <div className='addtruck-save-button'> 
                        <Button onClick={handleSaveButton}> Save </Button>
                    </div> 

                    <div className='addtruck-save-button'> 
                        <Button onClick={() => {setAddtruckWarningMessage({type :"prompt", message: "Are you sure you want to abandon changes?", action: handleDismissButton})}}> Dismiss </Button>  
                    </div> 

                    <div onClick={handleDeleteButton} className={` ${formType !== "edit" ? 'addtruck-hide' : ''}`} >
                         <img src={deleteIcon} style={{cursor: "pointer"}} alt="Icon" width={24} height={24} />;
                    </div>

                </div>
            </div>

            <div className='addtruck-bottom-phoneview'></div>

            <div className={` ${addtruckWarningMessage.message.length ? '' : 'addtruck-hide'}  addtruck-warning-message`}>

                <p>{addtruckWarningMessage.message}</p>

                {
                addtruckWarningMessage.type === "info" ? 
                <div className='addtruck-warning-button-ok' > <Button onClick={() => {setAddtruckWarningMessage({type :"", message: "", action: null})}} >ok</Button> </div> :
                <div className='addtruck-warning-button-container'>
                    <div className='addtruck-warning-button-cancel'><Button onClick={() => {setAddtruckWarningMessage({type :"", message: "", action: null})}} >Cancel</Button></div>
                    <div className='addtruck-warning-button-continue'><Button onClick={() => {addtruckWarningMessage.action(); setAddtruckWarningMessage({type :"", message: "", action: null})}} >Continue</Button></div>
                </div>
                }
            </div>
            <div className={`  ${addtruckWarningMessage.length ? '' : 'addtruck-hide'}  overlay`}></div>

        </div>
    )

}

export default Addtruck;