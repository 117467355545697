import { useState } from 'react';
import './filter.styles.scss';
import filterIcon from '../../assets/img/filter.png'


const Filter = () => {

const [minRangeValue, setMinRangeValue] = useState(0);
const [maxRangeValue, setMaxRangeValue] = useState(100);
const [margin, setMargin] = useState("1%");
const [width, setWidth] = useState(Number(maxRangeValue)-Number(minRangeValue));
const [filterDisplay , setFilterDisplay] = useState('slide-up')

const handleChangeMin =  (e) => {
    if(Number(e.target.value)<Number(maxRangeValue-10)){
        setMargin(Number(e.target.value));
        setWidth(Number(maxRangeValue)-Number(e.target.value) );
        setMinRangeValue(Number(e.target.value));

        
    } 
}

const handleChangeMax = (e) => {
    if(Number(e.target.value)>Number(minRangeValue+10)){
    setWidth(Number(e.target.value)-Number(minRangeValue));
    setMaxRangeValue(Number(e.target.value));
    }
}

const handleFilterDisplay = () => {
    if(filterDisplay === 'slide-up') {
        setFilterDisplay('slide-down')
    } else {
        setFilterDisplay('slide-up')
    }
    
}
    return(
    <div className='positioning'>  
        <div className='filtering-icon' onClick={handleFilterDisplay}>
            <img src={filterIcon} alt='filter-icon' className='filter-icon-size'  /> Filter
        </div>
        <div  className={`wrap-filter ${filterDisplay}`} >
            <h1>Filter by:</h1>
            <div className="money-filter">
                <h2  style={{fontSize: "20px"}}>Your Budget</h2>
                <p style={{fontSize: "20px"}}>MAD {minRangeValue} - MAD {maxRangeValue}</p>
            
                <div className='range-wrapper'>
                    <span className="slider-track"></span>
                    <input 
                        style={{ marginLeft: `${minRangeValue===0? "-1%": "0%"}` }}
                        className='min-range'
                        type='range' 
                        min="0"
                        max="100"
                        value={minRangeValue}
                        onChange={handleChangeMin}
                        
                    />
                    <input 
                        // style={{ marginLeft: `${"1%"}` }}
                        className='max-range'
                        type='range' 
                        min="0"
                        max="100"
                        value={maxRangeValue}
                        onChange={handleChangeMax}
                        
                    />
                <div className="progress" style={{ width: `${width+"%"}`, marginLeft: `${margin+"%"}` }} ></div>
            </div>
            </div>
            <div className='type-filter'>
                    <h2>Type of Transport</h2>
                    <div>
                        <input  type="checkbox" id="Frigo" name="Frigo"  />
                        <label style={{fontSize: "20px"}} for="frigo">Frigo</label>
                    </div>
                    <div>
                        <input type="checkbox" id="Sec" name="Sec"  />
                        <label style={{fontSize: "20px"}} for="sec">Sec</label>
                    </div>
                    <div>
                        <input type="checkbox" id="Plateau" name="Plateau"  />
                        <label style={{fontSize: "20px"}} for="Plateau">Plateau</label>
                    </div>
                    <div>
                        <input type="checkbox" id="Camion-Fourgon" name="Camion-Fourgon"  />
                        <label style={{fontSize: "20px"}} for="Camion-Fourgon">Camion Fourgon</label>
                    </div>
                    <div>
                        <input type="checkbox" id="Camion-Port-Chart" name="Camion-Port-Chart"  />
                        <label style={{fontSize: "20px"}} for="Camion-Port-Chart">Camion Port Chart</label>
                    </div>
                    <div>
                        <input type="checkbox" id="Camion-Port-Voiture" name="Camion-Port-Voiture"  />
                        <label style={{fontSize: "20px"}} for="Camion-Port-Voiture">Camion Port Voiture</label>
                    </div>
                    <div>
                        <input type="checkbox" id="Camion-Van" name="Camion-Van"  />
                        <label style={{fontSize: "20px"}} for="Camion-Van">Camion Van</label>
                    </div>
                </div>
        </div>
    </div>
    )
}

export default Filter;