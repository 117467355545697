import './cards.styles.scss' ;

 

const CardItems =({title, type, location, price})=> {
    return (
<div>
    <div className='card-container'> 
         <div className='card-header'>{title}
         <div className='type'>{type}</div>
          </div>
            <div className='card-description'> 
                <div className='card-price'><p className='p1'> {price}</p></div>
                <div className='card-location'>{location}</div>
                <div className='card-details'> <p> Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                     
                    </p> </div>
            </div>

            <button className='card-button-test' id='book'> book </button> 
        
        <div></div>
           
        </div>
      
      
           
           
 </div>
            )

}


export default CardItems;
