import React from 'react';
import sample from './headlineimage.png';
import Button from '../../components/button/button.component';
import './headline.styles.scss';

const Headline = () => {


  return (
        <div className="headline-global">
          <div className='headline-container'>
             <div className='headline-text-container'>
                <h1 className="headline-text-header">Best Way to Minimize Cost</h1> 
                <h1 className="headline-text-paragraph">Ravostrat is your innovative solution for optimizing freight transportation by seamlessly connecting shippers and carriers.</h1>
                <div className='headline-join-now-button'>
                  <Button buttonType="inverted"> join for free </Button>
              </div>
              </div>
              <img src={sample} alt="headlineimage" className='headline-image'/>
              
          </div>
              <svg className="wave" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 200">
                  <path fill="white"  d="M0,150C240,10,960,200,1440,180L1440,200L0,200Z"></path> 
              </svg>
           
        </div>
    

  );
}

export default Headline;